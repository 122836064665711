import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const Seo = ({ lang, title, meta, description, keywords, image }) => {
    const { theme, site } = useStaticQuery(graphql`
        query {
            theme: prismicTheme {
                ...ThemeQuery
            }
            site: site {
                siteMetadata {
                    siteUrl
                }
            }
        }
    `)

    const siteName = theme.data.sitename.text || "Living Creations"
    const metaTitle = title != null ? title : siteName
    const metaImage =
        image == null || image.fixed == null
            ? `${theme.data.seo_image.fixed.src}`
            : `${image.fixed.src}`
    const metaDescription = description || theme.data.seo_description.text
    const metaKeywords = keywords || theme.data.seo_keywords

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={metaTitle}
            meta={[
                {
                    name: `viewport`,
                    content: `width=device-width, initial-scale=1, maximum-scale=1, shrink-to-fit=no`,
                },
                {
                    property: `image`,
                    content: metaImage,
                },
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    name: `keywords`,
                    content: metaKeywords,
                },
                {
                    name: `language`,
                    content: lang,
                },
                {
                    property: `og:title`,
                    content: metaTitle,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:image`,
                    content: metaImage,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:title`,
                    content: metaTitle,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
                {
                    name: `p:domain_verify`,
                    content: `f794afeb2a703f0c22506affc2a660a0`,
                },
                {
                    name: `google-site-verification`,
                    content: `V_FJ51c56VxFAFGwZIBhP4jFUBLzBcHK2Eqy3pVqXA4`,
                },
            ].concat(meta)}
        ></Helmet>
    )
}

Seo.defaultProps = {
    lang: `en`,
    meta: [],
    title: ``,
    description: ``,
    keywords: ``,
    image: ``,
}

Seo.propTypes = {
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string,
    description: PropTypes.string,
    keywords: PropTypes.string,
}

export default Seo
